<template>
  <div class="container" id="main-page">
    <div class="row justify-content-center">
      <div class="col-md-11">
        <div class="container p-2">
          <div class="contact-us">
            <div class="row justify-content-center row-header">
              <div class="col-12 container-back pl-3">
                <div class="history-header">
                  <Back />
                  <span> Kontak Kami</span>
                </div>
              </div>
              <div class="col-12 mt-5 mb-3 text-center">
                <img
                  src="https://www.sekolahdesain.id/assets/images/sekdes.png"
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>
            <div class="card-profile">
              <div class="row">
                <div class="col">
                  <div class="judul">
                    <p style="font-size: 1.2rem">
                      Hubungi Kami
                    </p>
                  </div>
                  <div class="isi">
                    <div class="row">
                      <div class="col-12 d-flex align-items-center">
                        <i class="fas fa-phone-alt"></i>
                        <span> 0817-7085-5839 </span>
                      </div>
                    </div>
                  </div>
                  <div class="border-bwh"></div>
                  <div class="isi">
                    <div class="row">
                      <div class="col-12 d-flex align-items-center">
                        <i class="fas fa-envelope"></i>
                        <span>admin@sekolahdesain.id</span>
                      </div>
                    </div>
                  </div>
                  <div class="border-bwh"></div>
                  <div class="isi">
                    <div class="row">
                      <div class="col-12 d-flex align-items-center">
                        <i class="fas fa-map-marker-alt"></i>
                        <span>
                          Office Park B-19, The Park Solobaru, Madegondo,
                          Grogol, Sukoharjo 57552
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
::v-deep(html, body) {
  background: #00446e !important;
}
</style>
